import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@frontend-components/components/Button';
import styles from './QuestionButtons.module.scss';
import { Tooltip } from '@mui/material';
const QuestionButtons = ({ buttons, selectedButtonValue, onClick, identifier }) => {
    const buttonsElement = buttons.map((button) => (_jsx(Tooltip, { title: button.tooltip, children: _jsxs("div", { className: `${styles.buttonWrapper} ${button.description != null && button.description.length > 0 ? styles.withDescription : null}`, children: [_jsx(Button, { "data-testid": `button-${identifier}-${button.value}`, 
                    // eslint-disable-next-line no-nested-ternary
                    kind: Array.isArray(selectedButtonValue) ? selectedButtonValue.includes(button.value) ? 'primary' : 'secondary' : selectedButtonValue === button.value ? 'primary' : 'secondary', className: styles.button, onClick: () => onClick(button.value), children: button.text }), button.description ? _jsx("p", { className: styles.description, children: button.description }) : null] }, `${button.text}-${button.value}`) }, `${button.text}-${button.value}`)));
    return (_jsx("div", { className: styles.wrapper, children: buttonsElement }));
};
export default QuestionButtons;
