var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { bookingUrl } from '@constants';
import { Requester } from '@frontend-components/utils/requester';
export function getBookings(_a) {
    return __awaiter(this, arguments, void 0, function* ({ purchaseType, partner, products, from, to }) {
        // eslint-disable-next-line max-len
        const { data } = yield Requester.get(`${bookingUrl}/api/bookings/timeslot/availability?notice_type=external&purchaseType=${purchaseType}&partner=${partner}&productTypes=${products}&from=${from}&to=${to}`);
        return data;
    });
}
