var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef, useCallback } from 'react';
import { useThrottle } from '@frontend-components/hooks/useThrottle';
import { mapAddressResponse } from './AddressAutoComplete.utils';
import Input from '@frontend-components/components/Input';
import IconButton from '@frontend-components/components/IconButton';
import Dropdown from './components/Dropdown';
import { inputThrottleTimeoutMs } from './AddressAutoComplete.constants';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import styles from './AddressAutoComplete.module.scss';
const AddressAutoComplete = (_a) => {
    var { value, onChange, onItemSelect } = _a, rest = __rest(_a, ["value", "onChange", "onItemSelect"]);
    const inputRef = useRef(null);
    const [autocompleteSearchQuery, setAutoCompleteSearchQuery] = useState(value);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [isFocused, setFocused] = useState(false);
    const [isAutoCompleteDisabled, setIsAutoCompleteDisabled] = useState(true);
    const [autoCompleteItems, setAutoCompleteItems] = useState([]);
    const [highlightedItemIndex, setHighlightedItemIndex] = useState(-1);
    const throttledAutoCompleteValue = useThrottle(autocompleteSearchQuery, inputThrottleTimeoutMs);
    const isDropdownVisible = isFocused && autoCompleteItems.length > 0;
    const handleSelectAutoCompleteItem = useCallback((address) => {
        var _a;
        if (onItemSelect != null)
            onItemSelect(address);
        setSelectedAddress(address);
        setIsAutoCompleteDisabled(true);
        setAutoCompleteSearchQuery((_a = address === null || address === void 0 ? void 0 : address.full_address) !== null && _a !== void 0 ? _a : '');
        setAutoCompleteItems([]);
        setHighlightedItemIndex(-1);
    }, [onItemSelect]);
    const performStreetSearch = () => __awaiter(void 0, void 0, void 0, function* () {
        if (throttledAutoCompleteValue == null)
            return;
        if (throttledAutoCompleteValue.length <= 2 || isAutoCompleteDisabled) {
            setAutoCompleteItems([]);
            return;
        }
        try {
            const result = yield fetch(`https://api.dataforsyningen.dk/adresser?struktur=mini&q=${throttledAutoCompleteValue}`).then((response) => response.json());
            setAutoCompleteItems(mapAddressResponse(result));
        }
        catch (_a) {
            console.error('Error');
        }
    });
    const handleChangeInput = useCallback((event) => {
        setAutoCompleteSearchQuery(event.target.value.trim());
        if (onChange != null)
            onChange(event.target.value);
        if (isAutoCompleteDisabled) {
            setTimeout(() => setIsAutoCompleteDisabled(false), inputThrottleTimeoutMs);
        }
    }, [isAutoCompleteDisabled, onChange]);
    const handleClickResetAddress = useCallback(() => {
        setSelectedAddress(null);
        setAutoCompleteSearchQuery('');
        if (onItemSelect != null)
            onItemSelect(null);
        if (onChange != null)
            onChange('');
    }, []);
    const handleFocusInput = useCallback(() => {
        if (isAutoCompleteDisabled) {
            setIsAutoCompleteDisabled(false);
        }
        setFocused(true);
        performStreetSearch();
    }, [isAutoCompleteDisabled, throttledAutoCompleteValue]);
    const handleBlurInput = useCallback(() => {
        if (autoCompleteItems.length > 0) {
            setAutoCompleteItems([]);
        }
        if (highlightedItemIndex >= 0) {
            setHighlightedItemIndex(-1);
        }
        setFocused(false);
    }, [autoCompleteItems]);
    useEffect(() => {
        performStreetSearch();
    }, [throttledAutoCompleteValue]);
    return (_jsxs("div", { ref: inputRef, children: [_jsxs("div", { className: styles.inputWrapper, children: [_jsx(Input, Object.assign({}, rest, { type: "search", "aria-autocomplete": "list", "aria-expanded": isDropdownVisible, spellCheck: "false", autoCapitalize: "none", value: autocompleteSearchQuery, onChange: handleChangeInput, autoComplete: "off", onFocus: handleFocusInput, onBlur: handleBlurInput, onKeyDown: handleKeyDownDropdown, disabled: selectedAddress != null, role: "combobox" })), selectedAddress != null && (_jsx(IconButton, { title: "Delete", icon: faClose, className: styles.removeIcon, onClick: handleClickResetAddress }))] }), isDropdownVisible ? (_jsx(Dropdown, { inputRef: inputRef, autoCompleteItems: autoCompleteItems, onItemSelect: handleSelectAutoCompleteItem, highlightedItemIndex: highlightedItemIndex })) : null] }));
    function handleKeyDownDropdown(event) {
        const { key } = event;
        if (key === 'Escape' && isDropdownVisible) {
            event.preventDefault();
            event.stopPropagation();
            setAutoCompleteItems([]);
            setHighlightedItemIndex(-1);
            return;
        }
        if (!isDropdownVisible && throttledAutoCompleteValue != null && throttledAutoCompleteValue.length > 0 && (key === 'ArrowDown' || key === 'ArrowUp')) {
            performStreetSearch();
            return;
        }
        if (key === 'ArrowDown') {
            let newIndex = highlightedItemIndex + 1;
            if (newIndex > autoCompleteItems.length - 1) {
                newIndex = 0;
            }
            setHighlightedItemIndex(newIndex);
            return;
        }
        if (key === 'ArrowUp') {
            let newIndex = highlightedItemIndex - 1;
            if (newIndex < 0) {
                newIndex = autoCompleteItems.length - 1;
            }
            setHighlightedItemIndex(newIndex);
            return;
        }
        if (key === 'Enter' && highlightedItemIndex >= 0 && autoCompleteItems.length > 0) {
            event.preventDefault();
            event.stopPropagation();
            handleSelectAutoCompleteItem(autoCompleteItems[highlightedItemIndex]);
        }
    }
};
export default AddressAutoComplete;
